<template>
  <div class="p-index">
    <headers></headers>
    <div  class="bg"></div>
    <div class="content">
      <el-breadcrumb  style="padding: 15px 0" separator="/">
        <el-breadcrumb-item><a>申请新企业</a></el-breadcrumb-item>
      </el-breadcrumb>
      <div style="background: #fff;padding: 30px;">
        <el-form ref="form" :rules="rules" style="width:800px;margin: 0 auto" :model="form" label-width="180px">
          <el-form-item label="企业名称" prop="name">
            <el-input v-model="form.name" placeholder="请输入企业名称"></el-input>
          </el-form-item>
          <el-form-item label="统一社会信用代码" prop="usci">
            <el-input v-model="form.usci" placeholder="请输入统一社会信用代码"></el-input>
          </el-form-item>
          <!--          <el-form-item label="企业LOGO">-->
          <!--            <div style="position: relative">-->
          <!--              <input type="file" class="attachment" ref="attachment"  @change="uploadFile">-->
          <!--              <i class="el-icon-plus"></i>-->
          <!--              <span  class="zhizhao" v-if="form.logo">-->
          <!--              <img :src="form.logo"/>-->
          <!--              </span>-->
          <!--            </div>-->
          <!--          </el-form-item>-->
          <!--          <el-form-item label="注册时间" prop="regTime">-->
          <!--            <el-date-picker-->
          <!--                v-model="form.regTime"-->
          <!--                type="date"-->
          <!--                value-format="yyyy-MM-dd"-->
          <!--                format="yyyy-MM-dd"-->
          <!--                placeholder="请选择注册日期">-->
          <!--            </el-date-picker>-->
          <!--          </el-form-item>-->
          <!--          <el-form-item label="注册资本（万元）" prop="regCapital">-->
          <!--            <el-input v-model="form.regCapital" placeholder="请输入注册资本（万元）"></el-input>-->
          <!--          </el-form-item>-->
          <el-form-item label="法定代表人姓名" prop="legalPersonName">
            <el-input v-model="form.legalPersonName" placeholder="请输入法定代表人姓名"></el-input>
          </el-form-item>
          <!--          <el-form-item label="法人证件类型">-->
          <!--            <el-select v-model="form.legalPersonIdType" placeholder="请选择证件类型">-->
          <!--              <el-option :label="item.title" v-for="(item,index) in COMMON_ID_TYPE" :key="index" :value="item.id"></el-option>-->
          <!--            </el-select>-->
          <!--          </el-form-item>-->
          <!--          <el-form-item label="法人证件号码">-->
          <!--            <el-input v-model="form.legalPersonId" placeholder="请输入法人证件号码"></el-input>-->
          <!--          </el-form-item>-->
          <!--          <el-form-item label="法定代表人电话"  prop="name">-->
          <!--            <el-input v-model="form.legalPersonPhone" placeholder="请输入法定代表人电话"></el-input>-->
          <!--          </el-form-item>-->
          <!--          <el-form-item label="注册地址"  prop="regAddress">-->
          <!--            <el-input v-model="form.regAddress" placeholder="请输入注册地址"></el-input>-->
          <!--          </el-form-item>-->
          <!--          <el-form-item label="所属行业"  prop="industry">-->
          <!--            <el-select v-model="form.industry" placeholder="请选择所属行业">-->
          <!--              <el-option :label="item.title" v-for="(item,index) in industryList" :key="index" :value="item.id"></el-option>-->
          <!--            </el-select>-->
          <!--          </el-form-item>-->
          <!--          <el-form-item label="企业简介">-->
          <!--            <el-input type="textarea" v-model="form.description" placeholder="请输入企业简介"></el-input>-->
          <!--          </el-form-item>-->
          <!--          <el-form-item label="经营范围"  prop="businessScope">-->
          <!--            <el-input type="textarea" v-model="form.businessScope" placeholder="请输入经营范围"></el-input>-->
          <!--          </el-form-item>-->
          <el-form-item label="联系人"  prop="contactPerson">
            <el-input v-model="form.contactPerson" placeholder="请输入联系人"></el-input>
          </el-form-item>
          <el-form-item label="联系电话"  prop="contactPhone">
            <el-input v-model="form.contactPhone" placeholder="请输入联系电话"></el-input>
          </el-form-item>
          <el-form-item label="营业执照"  prop="license">
            <div style="position: relative">
              <input type="file" class="attachment" ref="attachment1"  @change="uploadFile1">
              <i class="el-icon-plus"  v-if="!form.license"></i>
              <span  class="zhizhao" v-if="form.license">
              <img :src="form.license"/>
              </span>
            </div>
          </el-form-item>
          <el-form-item label="企业邮箱" prop="contactEmail">
            <el-input v-model="form.contactEmail" placeholder="请输入企业邮箱"></el-input>
          </el-form-item>
          <el-form-item label="所属园区" prop="reportDeptName">
          
            <el-select v-model="form.reportDeptName" placeholder="请选择">
    <el-option>
      <el-tree
        :data="deptList"
        :props="defaultProps"
        @node-click="handleNodeDeptClick"
      />
    </el-option>
  </el-select>
          </el-form-item>


          <!--          <el-form-item label="申请人姓名"  prop="reqPersonName">-->
          <!--            <el-input v-model="form.reqPersonName" placeholder="请输入申请人姓名"></el-input>-->
          <!--          </el-form-item>-->
          <!--          <el-form-item label="申请人证件类型"  prop="reqPersonIdType">-->
          <!--            <el-select v-model="form.reqPersonIdType" placeholder="请选择证件类型">-->
          <!--              <el-option :label="item.title" v-for="(item,index) in COMMON_ID_TYPE" :key="index" :value="item.id"></el-option>-->
          <!--            </el-select>-->
          <!--          </el-form-item>-->
          <!--          <el-form-item label="申请人证件号码"  prop="reqPersonId">-->
          <!--            <el-input v-model="form.reqPersonId" placeholder="请输入申请人证件号码"></el-input>-->
          <!--          </el-form-item>-->
          <!--          <el-form-item label="申请人手机号码"  prop="reqPersonPhone">-->
          <!--            <el-input v-model="form.reqPersonPhone" placeholder="请输入申请人手机号码"></el-input>-->
          <!--          </el-form-item>-->
          <!--          <el-form-item label="申请人职位"  prop="reqPersonPost">-->
          <!--            <el-input v-model="form.reqPersonPost" placeholder="请输入申请人职位"></el-input>-->
          <!--          </el-form-item>-->
          <el-form-item>
            <el-button type="primary" @click="onSubmit('form')">提交审核</el-button>
            <el-button @click="goback">返回</el-button>
          </el-form-item>
        </el-form>
      </div>
    </div>
    <bottom></bottom>
  </div>
</template>

<script>
import headers from '../../components/headers'
import bottom from "../../components/bottom";
import {batchupload, dictbizlist, loginindex, reqnewenterprise,getDepts} from "../../request/moudle";
import {validateUnifiedSocialCreditCode} from "../../utils/index"
export default {
  name: "qyyd",
  data(){
    var validatePass = (rule, value, callback) => {
      if (value == '') {
        callback(new Error('请填写统一社会信用代码'));
      } else if(!validateUnifiedSocialCreditCode(value)){
        callback(new Error('请填写正确的社会信用代码'));
      }else {
        callback();
      }
    };

    var checkEmail = (rule, value, callback) => {//邮箱校验
      const mailReg = /^([a-zA-Z0-9_-])+@([a-zA-Z0-9_-])+(.[a-zA-Z0-9_-])+/
      if (!value) {
        return callback(new Error('邮箱不能为空!'))
      }
      setTimeout(() => {
        if (mailReg.test(value)) {
          callback()
        } else {
          callback(new Error('请输入正确的邮箱格式!'))
        }
      }, 100)
    };

    return {
      COMMON_ID_TYPE:[],
      industryList:[],
      deptList:[],
      defaultProps:{
        children: 'children',
          label: 'title',
          value: 'id'
      },
      fileData:'',
      form:{
        name:'',
        usci:'',
        regTime:'',
        regCapital:'',
        legalPersonName:'',
        legalPersonIdType:'',
        legalPersonId:'',
        regAddress:'',
        industry:'',
        description:'',
        legalPersonPhone:'',
        businessScope:'',
        contactPerson:'',
        contactPhone:'',
        contactEmail:'',
        reqPersonName:'',
        reqPersonIdType:'',
        reqPersonId:'',
        reqPersonPhone:'',
        reqPersonPost:'',
        license:'',
        logo:'',
        reportDeptId:'',
        reportDeptName:''
      },
      imageUrl:'',
      rules:{
        name: [
          { required: true, message: '请填写企业名称', trigger: 'blur' }
        ],
        usci:[
          { validator: validatePass, required: true, trigger: 'blur'}
        ],
        // regTime:[
        //   { required: true, message: '请填写注册时间', trigger: 'change',  }
        // ],
        // regCapital: [
        //   { required: true, trigger: 'blur', message: '请填写注册资本'  }
        // ],
        // legalPersonPhone: [
        //   { required: true, trigger: 'blur', message:'请填写法定代表人电话' }
        // ],
        legalPersonName: [
          { required: true, trigger: 'blur', message:'请填写法定代表人姓名' }
        ],
        // regAddress:[
        //   { required: true, trigger: 'change', message: '请填写注册地址' }
        // ],
        // industry: [
        //   { required: true, trigger: 'blur', message: '请选择所属行业' }
        // ],
        // businessScope: [
        //   { required: true, trigger: 'blur', message: '请填写经营范围'  }
        // ],
        contactPerson: [
          { required: true, trigger: 'blur', message:'请填写联系人' }
        ],
        contactPhone: [
          { required: true, trigger: 'blur', message:'请填写联系电话'}
        ],
        contactEmail: [
          { required: true, validator: checkEmail, trigger: 'blur'}
        ],
        license: [
          { required: true, trigger: 'blur', message:'请填写'}
        ],
        reportDeptName: [
          { required: true,  message:'请选择园区'}
        ],
        // reqPersonName: [
        //   { required: true, trigger: 'blur', message:'请填写申请人姓名'}
        // ],
        // reqPersonPhone: [
        //   { required: true, trigger: 'blur', message: '请填写申请人手机号码' }
        // ],
        // reqPersonPost: [
        //   { required: true, message: '请填写申请人职位', trigger: 'blur' }
        // ],
        // contactEmail: [
        //   { required: true, validator: checkEmail, trigger: 'blur' }
        // ]
      }
    }
  },
  created() {
    this.loadconfig()
    getDepts({parentCode: '13098301'}).then(res=>{
      console.log('请求成功',res.data);
      this.deptList=res.data;
    }).catch();
  },
  methods:{
    handleNodeDeptClick(data){
      console.log('园区',data);
      this.form.reportDeptName=data.title
      this.form.reportDeptId=data.id
    },
    loadconfig(){
      this.$api.index.loginindex().then((res)=>{
        this.COMMON_ID_TYPE=res.data.cardTypeList
        this.industryList=res.data.industryList
      })
    },
    uploadFile(event){
      console.log(event)
      let inputDOM = this.$refs.attachment;
      //通过DOM取文件数据
      this.fileData = inputDOM.files;
      let _file = this.fileData[0]
      if(!_file){return;}
      let size = Math.floor(_file.size / 1024);
      if (size > this.maxSize) {
        this.$toast('单个文件大小不超过15M！');
        return false
      }

      if(!/image\/\w+/.test(_file.type)){
        this.$toast('请确保文件为图片类型')
        return false;
      }
      console.log(_file,'_file')
      var fd = new FormData();
      fd.append("file", _file);
      this.$api.index.batchupload(fd).then((res)=>{
        this.form.logo=res.data.link
      })
    },
    uploadFile1(event){
      console.log(event)
      let inputDOM = this.$refs.attachment1;
      //通过DOM取文件数据
      this.fileData = inputDOM.files;
      let _file = this.fileData[0]
      if(!_file){return;}
      let size = Math.floor(_file.size / 1024);
      if (size > this.maxSize) {
        this.$toast('单个文件大小不超过15M！');
        return false
      }

      if(!/image\/\w+/.test(_file.type)){
        this.$toast('请确保文件为图片类型')
        return false;
      }
      console.log(_file,'_file')
      var fd = new FormData();
      fd.append("file", _file);
      this.$api.index.batchupload(fd).then((res)=>{
        this.form.license=res.data.link
      })
    },
    onSubmit(formName){
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.$api.index.reqnewenterprise(this.form).then((res)=>{
            this.$message('您的注册申请已提交成功，登录账号和密码发至邮箱，请您关注邮件动态')
            this.$router.push({
              path:'/login'
            })
          })
        } else {
          return false;
        }
      });

    },
    goback(){
      this.$router.go(-1)
    }
  },
  components: {
    headers, bottom
  },
}
</script>

<style lang="scss" scoped>
.el-icon-plus{
  font-size: 30px;
  color: #606266;
  margin: 5px 10px;
}
.attachment{
  position: absolute;
  left: 7px;
  top: -3px;
  z-index: 999;
  opacity: 0;
  height: 50px;
  width: 50px;
}
.zhizhao{
  width: 50px;
  height: 50px;
  position: absolute;
  left: 7px;
  top: -3px;
  z-index: 900;
  img{
    width: 100%;
    height: 100%;
  }
}
.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader .el-upload:hover {
  border-color: #409EFF;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}
.avatar {
  width: 178px;
  height: 178px;
  display: block;
}
/deep/ .el-input{
  width: 300px;
}
.p-index{
  background: #f5f5f5;
}
.bg{
  width: 100%;
  height: 200px;
  background: url("../../assets/icons/personindex.png") no-repeat;
  background-size: cover;
}
.content{
  width: 1200px;
  margin: 0 auto;
  min-height: 1000px;
}

/deep/.el-textarea__inner::placeholder {
  font-family: "Helvetica Neue", Helvetica, "PingFang SC", "Hiragino Sans GB", "Microsoft YaHei", "微软雅黑", Arial, sans-serif;
}

/deep/.el-textarea__inner {
  font-family: "Helvetica Neue", Helvetica, "PingFang SC", "Hiragino Sans GB", "Microsoft YaHei", "微软雅黑", Arial, sans-serif;
}
/deep/ .el-form-item__error {
  top: 80%;
  left: 10px;
}
</style>